import React, { useState, useContext } from "react"
import styled from "styled-components"
import FlatlistContext from "../../context/FlatlistContext"
import ProductContext from "../../context/ProductContext"
import BranchContext from "../../context/BranchContext"
import Button from "../ProductSingle/Button"
import Table from "./table"
import { slugify } from "voca"

const entriesPerPage = 50

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Flatlist = ({ location }) => {
  const { flatlistProducts } = useContext(FlatlistContext)
  const { stockByLocation } = useContext(ProductContext)
  const { selectedBranch } = useContext(BranchContext)
  const [pagesLoaded, setPagesLoaded] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")
  const [searchResults, setSearchResults] = useState(flatlistProducts)

  // Handle a changing search term
  const handleChange = (event) => {
    setSearchTerm(event.target.value)
    if (event.target.value != "") {
      const results = flatlistProducts.filter(
        (item) =>
          slugify(item.title).includes(slugify(event.target.value)) ||
          slugify(item.sku).includes(slugify(event.target.value))
      )
      setSearchResults(results)
    } else {
      setSearchResults(flatlistProducts)
    }
  }

  // Only show first page of results
  const resultsToRender = searchResults
    .slice(0, pagesLoaded * entriesPerPage)
    .map((currentValue) => {
      for (let i = 0; i < stockByLocation.length; i++) {
        if (!selectedBranch) {
          currentValue.eff = "-"
          currentValue.soh = "-"
          currentValue.avail = "-"
          return currentValue
        }

        if (stockByLocation[i].sku === currentValue.sku) {
          let eff = parseInt(
            (stockByLocation[i] &&
              stockByLocation[i][selectedBranch] &&
              stockByLocation[i][selectedBranch].effective) ||
              0
          )
          let soh = parseInt(
            (stockByLocation[i] &&
              stockByLocation[i][selectedBranch] &&
              stockByLocation[i][selectedBranch].soh) ||
              0
          )

          currentValue.eff = eff < 0 ? 0 : eff
          currentValue.soh = soh < 0 ? 0 : soh
          currentValue.avail = Math.min(currentValue.eff, currentValue.soh)
        }
      }

      return currentValue
    })

  return (
    <StyledContainter>
      <Input
        type="text"
        placeholder="Search by Title or SKU"
        value={searchTerm}
        onChange={handleChange}
      />
      {resultsToRender.length == 0 ? (
        <NoItems>
          <div>There are no products matching your search criteria</div>
        </NoItems>
      ) : (
        <Table
          headings={["Product", "Size", "SKU", "SOH", "Avail.", "Price Incl."]}
          values={resultsToRender || []}
        />
      )}
      {searchResults.length > resultsToRender.length && (
        <StyledButton
          label="Load More"
          onClick={() => setPagesLoaded((prev) => prev + 1)}
        />
      )}
    </StyledContainter>
  )
}

// ==========
// 	 STYLES
// ==========
const StyledContainter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div.error-text {
    text-align: center;
    margin: 10px 0;
    color: #fd3237;
  }
`
const NoItems = styled.div`
  padding: 30px;
  margin: 30px;
  border-radius: 10px;
  border: 2px solid #1a293c;
  background: #f9f9f9;
  text-align: center;
`
const StyledButton = styled(Button)`
  padding: 20px;
  margin: 40px auto;
  div {
    font-size: 24px;
  }
`
const Input = styled.input`
  margin: 20px auto;
  width: 720px;
  padding: 10px;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #223247;
  background-color: #dee3e8;
  font-size: 18px;
`

export default Flatlist
