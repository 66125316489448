import React from "react"
import Layout from "../zzz/layout"
import Flatlist from "../components/Flatlist/flatlist"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const FlatlistPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Flatlist />
    </Layout>
  )
}

export default FlatlistPage
